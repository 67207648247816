import React, { createContext, useContext, useState, useCallback, ReactNode } from 'react';
import { messages } from '../config/config';

interface ConfigState {
  errorMessage: string;
  title: string;
  message: string;
  enableLogin: boolean;
  enableForgot: boolean;
}
interface ConfigContextType {
  config: ConfigState;
  updateConfig: (newConfig: Partial<ConfigState>) => void;
}

const page = window.location.pathname.includes('forgot') ? 'forgotPassword':'login'

const initialState:ConfigState = {
  errorMessage: "",
  title: messages[page].default.title,
  message: messages[page].default.message,
  enableLogin: false,
  enableForgot: false
}

interface ConfigProviderProps {
  children: ReactNode; // Tipar children como ReactNode
}

const ConfigContext = createContext<ConfigContextType>({config:initialState,updateConfig:()=>{}});

export const ConfigProvider: React.FC<ConfigProviderProps> = ({ children }) => {

  const [config, setConfig] = useState<ConfigState>(initialState);
  const updateConfig = useCallback((newConfig:Partial<ConfigState>) => {
    setConfig((prevConfig) => ({ ...prevConfig, ...newConfig }));
  }, []);
  
  return (
    <ConfigContext.Provider value={{ config, updateConfig }}>
      {children}
    </ConfigContext.Provider>
  )

};

export const useConfig = () => {
  return useContext(ConfigContext);
};