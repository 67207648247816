import useSWR from 'swr'
import fetchDataService from '../services/fetchDataService'
import { generateFrontKeys } from '../services/securityService'


function useIndex(token:any){
  const tokenData = token ? {token: token.token} : {}
  const config = {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshWhenOffline: false,
    refreshWhenHidden: false,
    dedupingInterval: 5000
  }
  const fetcher = () => fetchDataService.post('/security/auth/load-config',tokenData,token.key)
  const {data,error} = useSWR(()=>token ? '/security/auth/load-config':null,fetcher,config)

  return {
    content: data,
    error
  }
}

function useToken(){
  const frontKeyFetcher = async () => {
    await generateFrontKeys
    const key = localStorage.getItem('frontKey')
    return {key}
  }
  const {data:frontKey} = useSWR('generatefrontkeys',frontKeyFetcher)

  const serverKeyFetcher = () => fetchDataService.post('/public/key',{},null)
  const tokenFetcher = (args:any) => fetchDataService.post(args[0],{
      typeToken: 'csrfToken',
      timeToken: 30
    },args[1]
  ) 
  const config = {
    revalidateIfStale: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: false,
    refreshWhenOffline: false,
    refreshWhenHidden: false,
    dedupingInterval: 60000
  }

  const {data:keyData} = useSWR(()=> frontKey ? '/public/key':null,serverKeyFetcher,config)
  const {data:tokenData} = useSWR(()=>keyData ? ['/security/auth/create-session',keyData.data]:null,
    tokenFetcher,
    config
  )

  return {
    tokenData,
  }
}
const Auth = {
  useIndex,
  useToken
}

export default Auth