export const messages = {
  login: {
    default: {
      title: "Bienvenido de nuevo!",
      message: "Validando ingreso...",
      forgotText: "¿Olvidaste la contraseña?",
      error: "No es posible establecer la conexión con el servidor en este momento",
      alert: "Por favor recargue la página e intente nuevamente",
      form: {
        emailPlaceholder: "Ingresa la direccion de correo...",
        passwordPlaceholder: "Contraseña",
        buttonText: "Ingresar"
      }
    }
  },
  forgotPassword:{
    default:{
      title: "¿Olvidaste la contraseña?",
      message: "Lo sabemos, esas cosas pasan. Solo ingresa tu dirección de correo electrónico y te enviaremos un link de recuperación!",
      error: "No es posible establecer la conexión con el servidor en este momento",
      accountText: " ¿Tienes una cuenta? Ingresa ahora!",
      form: {
        emailPlaceholder: "Ingresa la direccion de correo...",
        buttonText: "Recuperar contraseña"
      }
    }
  }
}
