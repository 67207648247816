let frontPubKey:string
let frontPrivKey:string

export async function encryptData(data:any,serverKey:string){
  await generateFrontKeys()
  const sessionKey = crypto.getRandomValues(new Uint8Array(32)); // 256 bits
  const iv:any = crypto.getRandomValues(new Uint8Array(16)); // Vector de inicialización

  const enc = new TextEncoder();
  const encodedMessage = enc.encode(JSON.stringify(data));
  const sesionAESKey =  await window.crypto.subtle.importKey("raw", sessionKey, { name: "AES-CBC" }, false, ["encrypt"])
  const encryptedMessageBuffer = await window.crypto.subtle.encrypt(
    { name: "AES-CBC", iv: iv },
    sesionAESKey,
    encodedMessage
  );

  const serverPublicKey  =  await importServerKey(serverKey)
  const encryptedSessionKeyBuffer =  await window.crypto.subtle.encrypt(
      {
          name: "RSA-OAEP"
      },
      serverPublicKey,
      sessionKey
  );
  return {  
    data: window.btoa(String.fromCharCode.apply(null, Array.from(new Uint8Array(encryptedMessageBuffer)))),
    sessionKey: window.btoa(String.fromCharCode.apply(null, Array.from(new Uint8Array(encryptedSessionKeyBuffer)))),
    iv: window.btoa(String.fromCharCode.apply(null, Array.from(new Uint8Array(iv)))),
    key: frontPubKey
  }
}

export async function decryptData(encryptedData:any){
  const encryptedSessionKeyBuffer = base64ToArrayBuffer(encryptedData.sessionKey);
  const ivBuffer = base64ToArrayBuffer(encryptedData.iv);

  const privateKey = await getClientPrivateKey();
  const sessionKeyBuffer = await window.crypto.subtle.decrypt(
    {
      name: "RSA-OAEP"
    },
    privateKey,
    encryptedSessionKeyBuffer
  );
  const sessionKey = new Uint8Array(sessionKeyBuffer);

  const decryptedResponse = await decryptDataWithSessionKey(encryptedData.data, sessionKey, ivBuffer);
  decryptedResponse.key = encryptedData.key
  //console.log(decryptedResponse)
  return decryptedResponse; 
}


export async function generateFrontKeys(){
  if(!frontPrivKey){
    const keyPair = await window.crypto.subtle.generateKey(
      {
        name: "RSA-OAEP",
        modulusLength: 2048,
        publicExponent: new Uint8Array([1, 0, 1]),
        hash: "SHA-256"
      },
      true,
      ["encrypt", "decrypt"]
    )
    
    const frontPubExpKey = await window.crypto.subtle.exportKey("spki", keyPair.publicKey);
    frontPubKey = window.btoa(String.fromCharCode(...new Uint8Array(frontPubExpKey)))
    const frontPrivExpKey = await window.crypto.subtle.exportKey("pkcs8", keyPair.privateKey);
    frontPrivKey =  window.btoa(String.fromCharCode(...new Uint8Array(frontPrivExpKey)))
    frontPubKey = btoa(formatKeyToPem(frontPubKey))
    localStorage.setItem('frontKey',frontPubKey)
    console.log('key generated')
  }else{
    console.log('Skip key generation')
  }
}

function importServerKey(serverPublicKeyPem:string){
  const keyData = Uint8Array.from(atob(removePemHeaderAndFooter(atob(serverPublicKeyPem))), c => c.charCodeAt(0)).buffer
  return window.crypto.subtle.importKey(
    "spki",
    keyData,
    {
        name: "RSA-OAEP",
        hash: "SHA-256"
    },
    false,
    ["encrypt"]
  )
}

function removePemHeaderAndFooter(pem:string) {
  return pem
    .replace(/-----BEGIN [^-]+-----/, '') // Eliminar el encabezado
    .replace(/-----END [^-]+-----/, '')   // Eliminar el pie
    .replace(/\s+/g, '');                 // Eliminar todos los espacios en blanco
}

function formatKeyToPem(base64Key:string){
  const pemHeader = "-----BEGIN PUBLIC KEY-----";
  const pemFooter = "-----END PUBLIC KEY-----";
  const pemKey = `${pemHeader}\n${base64Key.match(/.{1,64}/g)?.join('\n')}\n${pemFooter}`;
  return pemKey;
}

function getClientPrivateKey() {
  const privateKeyArrayBuffer = Uint8Array.from(atob(frontPrivKey), c => c.charCodeAt(0)).buffer;
  return window.crypto.subtle.importKey(
      "pkcs8",
      privateKeyArrayBuffer,
      {
          name: "RSA-OAEP",
          hash: "SHA-256"
      },
      true,
      ["decrypt"]
  );
}

async function decryptDataWithSessionKey (encryptedData:string, sessionKey:any, iv:any){
  const encryptedDataBuffer = base64ToArrayBuffer(encryptedData);

  const decryptedArrayBuffer = await window.crypto.subtle.decrypt(
    {
      name: "AES-CBC",
      iv: iv
    },
    await window.crypto.subtle.importKey("raw", sessionKey, { name: "AES-CBC" }, false, ["decrypt"]),
    encryptedDataBuffer
  );

  const decoder = new TextDecoder();
  const decryptedText = decoder.decode(decryptedArrayBuffer);
  return JSON.parse(decryptedText);
};

function base64ToArrayBuffer(base64:string) {
  const binaryString = window.atob(base64);
  return str2ab(binaryString);
};
function str2ab(str:string){
  const buf = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
};