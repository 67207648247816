import React from 'react'
import Login from './components/Login'

function App(){
  return (
    <React.Fragment>
      <Login></Login> 
    </React.Fragment>
  );
}

export default App;
