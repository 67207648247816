import React from 'react'
import ForgotPassword from './ForgotPassword'



function Index(){
  return(
    <React.Fragment>
      <ForgotPassword/>  
    </React.Fragment>
  )
}

export default Index