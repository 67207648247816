import React,{useEffect, useState} from "react";
import {messages} from "../config/config"
import useAuth from "../hooks/useAuth";
import { useConfig } from "../contexts/ConfigContext";
import { Button, Card, Col, Form, Input, Layout, Row, Spin } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Content } from "antd/es/layout/layout";
import Request from '../services/fetchDataService'
import FooterLayout from "./Footer";

function Login(){
  const params = new URLSearchParams(window.location.search)
  const redirectEncode = params.get('redirect') || ""
  const {tokenData} = useAuth.useToken()
  const {content,error:eIndex} = useAuth.useIndex(tokenData)
  const {config, updateConfig} = useConfig()
  const [spinning, setSpinning] = useState(true)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 500);
  const [form] = Form.useForm();
  let redirectDecode = ""
  useEffect(()=>{

    if(tokenData && content) {
      updateConfig(content)
      setSpinning(false)
    }
    const handleResize = () => setIsMobile(window.innerWidth < 500);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  },[tokenData,content])
  
  if(eIndex) alert('No fue posible conectar con el servidor')
  
  const onLogin = async (values:any) => {
    setSpinning(true)
    const data = {
      email: values.username,
      password: values.password
    }
    const loginResponse = await Request.post('/security/auth/authenticatesession',data,tokenData.key)
    try{
      redirectDecode = decodeURIComponent(escape(atob(redirectEncode)))
    }catch(err){console.log(err)}
    const redirect = redirectDecode ? redirectDecode : loginResponse.redirect
    if(loginResponse.status === 200){
      window.location.href = redirect
    }else{
      updateConfig({ errorMessage: loginResponse.errorMessage})
    }
    setSpinning(false)
  }

  return (
    <Layout style={{ minHeight: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Spin size="large" tip="Cargando..." fullscreen spinning={spinning} />
      <Content style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center", padding: 10 }}>
        <Card style={{width: "90%", maxWidth: 800, minHeight: "70vh", display: "flex", flexDirection: "column", justifyContent: "center", margin: 40,boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.2)",borderRadius: 12}}>
        <Row justify="center" gutter={16}>
          <Col xs={20} sm={14} md={14} lg={9} xl={10}>
            
              {/* Contenedor flexible para alinear elementos */}
              <div style={{ display: "flex", flexDirection: isMobile ? "column" : "row", alignItems: "center" }}>
                {/* Formulario */}
                <div style={{ flex: 1, padding: 20 }}>
                  <h4 className="title-login" style={{ textAlign: "center" }}>{config.title}</h4>
                  <p style={{marginBottom:"1.5rem",color:"#858796",textAlign:"center"}}>{config.message}</p>
                  <Form form={form} layout="vertical" onFinish={onLogin}>
                    <Form.Item label="" name="username" rules={[{required:true,min:8,message:"Por favor ingrese un correo válido",type:"email"}]}>
                      <Input prefix={<UserOutlined />} style={{borderRadius:"2rem", padding:"0.8rem 1.2rem",fontSize:"0.8rem"}} minLength={8} placeholder={messages.login.default.form.emailPlaceholder} />
                    </Form.Item>
                    <Form.Item label="" name="password" rules={[{required:true,min:4,message:"Por favor ingrese una contraseña"}]}>
                      <Input.Password prefix={<LockOutlined />} style={{borderRadius:"2rem", padding:"0.8rem 1.2rem",fontSize:"0.8rem"}} minLength={4} placeholder={messages.login.default.form.passwordPlaceholder} />
                    </Form.Item>
                    <Form.Item>
                      <Button type="primary" htmlType="submit" disabled={!(config.enableLogin)} block style={{borderRadius:"10rem",padding:"20px"}}>
                        {messages.login.default.form.buttonText}
                      </Button>
                    </Form.Item>
                  </Form>
                  <div style={{textAlign:"center"}}>
                    <h4 style={{color:"red"}}>{(config.errorMessage) || ''}</h4>
                  </div>
                  <hr />
                  <div style={{textAlign:"center"}}>
                    <a style={{fontSize:"80%",fontWeight:400}} href="forgot-password">
                    {messages.login.default.forgotText}
                    </a>
                  </div>
                </div>
              </div>
          </Col>
          <Col xs={0} sm={0} md={0} lg={10} xl={11}>
            {/* Imagen (Solo en pantallas grandes) */}
            {!isMobile && (
              <div className="image-login bg-image">
              </div>
            )}
          </Col>
        </Row>
        </Card>
      </Content>

      <FooterLayout />
    </Layout>
  );
}


export default Login;
