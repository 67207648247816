import React from "react"
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { Button, Card, Layout, Typography } from "antd";
import { Content } from "antd/es/layout/layout"

const { Title, Text } = Typography;

function Error(){
  const history = useHistory()
  return (
    <Layout style={{ minHeight: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Content style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
        <Card
          style={{
            textAlign: "center",
            padding: 40,
            boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.2)",
            borderRadius: 12,
            maxWidth: 500,
          }}
        >
          <ExclamationCircleOutlined
            style={{
              fontSize: "80px",
              color: "#ff4d4f",
              animation: "bounce 1.5s infinite",
            }}
          />
          <Title level={1} style={{ fontSize: "5rem", marginBottom: 0, color: "#1890ff" }}>404</Title>
          <Title level={3} style={{ marginBottom: 20 }}>Página No Encontrada</Title>
          <Text type="secondary">Lo sentimos, la página que buscas no existe.</Text>
          <br />
          <Button
            type="primary"
            size="large"
            style={{ marginTop: 20 }}
            onClick={() => history.push("/login")}
          >
            Volver al Inicio
          </Button>
        </Card>
      </Content>
    </Layout>
  )
}

export default Error